/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { uid } from 'react-uid';
import { withRouter } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { bindActionCreators, compose } from 'redux';
import ReactHtmlParser from 'react-html-parser';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import {
    object, shape, array, string, func, bool,
} from 'prop-types';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { FormHelperText } from '@material-ui/core';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import ResponsiveWrapper from '../../../UniversalTemplate/SubComponents/ResponsiveWrapper';

import { trackEvent } from '../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { getFeatureFlag } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getActiveABTests } from '../../../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';
import GiftFinderProductContainer from '../../../GiftFinderProduct/GiftFinderProductContainer';
// strips "font-family:" and ";" from value
const parseFont = (font) => font?.match(/:\s(.*?);/, '')?.[1] || null;

const useStyles = makeStyles((theme) => ({
    giftFinderGFB: {
        boxSizing: 'border-box',
        padding: '55px 0px',
        margin: '30px auto',
        maxWidth: '1400px',
        minHeight: '400px',
        width: '100%',
        textAlign: 'center',
        height: 'auto',
        '& .MuiFormLabel-filled + .MuiInput-formControl': {
            borderColor: theme.palette.giftFinderSelectColor,
        },
        '@media screen and (max-width: 1024px)': {
            maxWidth: '100%',
        },
        '& h2': {
            margin: 0,
            fontSize: '2em',
            lineHeight: '2em',
            fontWeight: '400',
            '@media screen and (max-width: 601px)': {
                fontSize: '1.2em',
                lineHeight: '1em',
                fontWeight: '400',
            },
        },
        '@media screen and (max-width: 600px)': {
            '& $fieldBlock': {
                marginBottom: '24px',
            },
        },
        '@media screen and (max-width: 376)': {
            '& $fieldBlock': {
                marginBottom: '20px',
            },
        },
        '@media screen and (max-width: 375px)': {
            '& $paperBody': {
                minHeight: '340px',
                maxWidth: '295px',
            },
            '& $headingImg': {
                minHeight: '18px',
                maxWidth: '20px',
            },
            '& $headingCopy': {
                fontSize: '20px',
            },
            '& $heading': {
                padding: '16px 0px 21px',
            },
            '& $select , $button': {
                minHeight: '40px',
                maxWidth: '220px',

            },
            '& $button': {
                margin: '-4px auto 0',
                width: '100%',
            },
            '& $fieldBlock': {
                marginBottom: '24px',
            },
            '& .MuiInputLabel-outlined': {
                transform: 'translate(14px, 15px) scale(1)',
            },
            '& [class*="shrink"]': {
                top: '6px',
                transform: 'translate(12px, -8px) scale(0.75)',
            },
            padding: '30px 0px',
        },
        '@media screen and (min-width: 375px) and (max-width: 414px)': {
            '& $select': {
                minHeight: '44px',
                maxWidth: '270px',
            },
        },
        '@media screen and (min-width: 376px) and (max-width: 414px)': {
            '& $paperBody': {
                minHeight: '361px',
                maxWidth: '350px',
            },
            '& $headingImg': {
                minHeight: '24px',
                maxWidth: '24px',
            },
            '& $heading': {
                padding: '12px 0px 18px',
            },
            '& $headingCopy': {
                fontSize: '20px',
            },
            '& $select': {
                minHeight: '44px',
                maxWidth: '286px',
            },
            '& $fieldBlock': {
                marginBottom: '26px',
            },
            '& $button': {
                minHeight: '48px',
                maxWidth: '286px',
                margin: '0px auto',
                width: '100%',
            },
            padding: '18px 0px',
        },
        '@media screen and (min-width: 415px) and (max-width: 1023px)': {
            '& $paperBody': {
                minHeight: '364px',
                maxWidth: '522px',
            },
            '& $headingImg': {
                minHeight: '24px',
                maxWidth: '24px',
            },
            '& $heading': {
                padding: '22px 0px 24px',
            },
            '& $select': {
                minHeight: '52px',
                maxWidth: '347px',
            },
            '& $headingCopy': {
                fontSize: '24px',
                lineHeight: '1em',
            },
            '& $fieldBlock': {
                marginBottom: '20px',
            },
            '& $button': {
                minHeight: '48px',
                maxWidth: '192px',
                width: '100%',
                margin: '0px auto 0',
            },
            padding: '18px 0px',
        },
        '@media screen and (min-width: 1024px) and (max-width:1439px)': {
            '& $paperBody': {
                minHeight: '268px',
                maxWidth: '854px',
            },
            '& $heading': {
                padding: '28px 0px 27px',
            },
            '& $select': {
                minHeight: '50px',
                maxWidth: '245px',
            },
            '& $fieldBlock': {
                maxWidth: '245px',
            },
            '& $button': {
                margin: '20px auto 0',
            },
            padding: '48px 0px',
        },
    },
    giftFinderGFBWidthGrid: {
        margin: 'auto',
    },
    lcpImage: {
        display: 'none',
        // does not display but is fetched earlier in the page process/render, improving LCP
    },
    giftFinderGFBBG: ({ dataSelector, imageSizes, imageQuality }) => ({
        backgroundColor: dataSelector.styling.background_color.color || theme.palette.cms?.giftFinderBackgroundColor || '#F2F2F2',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        [theme.breakpoints.up(1080)]: {
            backgroundImage: `url(${imageSizes.desktop}${imageQuality})`,
        },
        [theme.breakpoints.down(1080)]: {
            backgroundImage: `url(${imageSizes.tablet}${imageQuality})`,
        },
        [theme.breakpoints.down(600)]: {
            backgroundImage: `url(${imageSizes.mobile}${imageQuality})`,
        },
    }),
    container: {
        width: '100%',
        margin: '0 auto',
        '@media screen and (max-width: 601px)': {
            padding: '25px 39px',
        },
        '@media screen and (max-width: 375px)': {
            padding: '25px 15px',
        },
        '& .MuiGrid-item': {
            '@media screen and (max-width: 601px)': {
                padding: 0,
            },
        },
        '& .MuiGrid-container': {
            '@media screen and (max-width: 601px)': {
                marginTop: 0,
                width: '100%',
                margin: 0,
            },
        },
    },
    fieldBlock: {
        maxWidth: '315px',
        margin: '0 8px 0',
        position: 'relative',
        '& label': {
            fontSize: '16px',
        },
        '& [class*="shrink"]': {
            top: '12px',
            color: theme.palette.giftFinderSelectColor,
        },
        '& .MuiInput-select:focused': {
            background: theme.palette.myAccount?.container?.backgroundColor,
        },
        '& label + .MuiInput-formControl': {
            marginTop: 0,
        },
    },
    select: {
        width: '315px',
        minHeight: '52px',
        border: `solid 1px ${theme.palette.colorNeutral70}`,
        padding: '2px 12px 2px 0px',
        background: theme.palette.tiers?.card?.backgroundColor,
        fontSize: '1.2em',
        '& :focus': {
            borderRadius: 3,
            background: theme.palette.myAccount?.container?.backgroundColor,
        },
        '& .MuiNativeSelect-select': {
            padding: '7px 12px',
            color: theme.palette.giftFinderSelectColor,
            [theme.breakpoints.down(600)]: {
                padding: '8px 13px',
                margin: '6px 0px 0px',
                fontSize: '18px',
            },
            '@media screen and (max-width: 375px)': {
                fontSize: '0.938rem',
            },
        },
        '& .MuiNativeSelect-filled': {
            border: `solid 1px ${theme.palette.giftFinderSelectColor} `,
        },
        '& svg': {
            fill: theme.palette.colorNeutral70,
        },
    },
    button: {
        margin: '24px auto 0',
        borderRadius: '28px',
        backgroundColor: theme.palette.primaryButton,
        textTransform: 'uppercase',
        fontSize: '16px',
        fontWeight: '700',
        '&:focus-visible': {
            outline: '-webkit-focus-ring-color auto 1px',
        },
        '@media screen and (min-width: 601px)': {
            minWidth: 192,
            minHeight: 48,
        },
        [theme.breakpoints.down('md')]: {
            '&:hover': {
                color: theme.palette.white,
                backgroundColor: theme.palette.primaryButton,
            },
        },
    },
    heading: ({ dataSelector }) => ({
        margin: '0 auto',
        display: 'flex',
        width: 'fit-content',
        padding: '24px 0px 30px',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'auto',
        maxHeight: '118px',
        fontFamily: parseFont(dataSelector.styling.heading_font) || 'Arial, Verdana, Helvetica, sans-serif–',
    }),
    headingCopy: {
        fontSize: '2em',
    },
    headingImg: ({ dataSelector }) => ({
        position: 'relative',
        display: 'inline-block',
        maxWidth: '32px',
        minHeight: '32px',
        margin: '0 12px 0 0',
        '& svg': {
            margin: '0 0 0 -10px',
            display: 'inline-block',
        },
        '& svg *': {
            width: 'auto',
            display: 'inline-block',
            height: '100%',
            fill: dataSelector.styling.gift_icon_color.color,
        },
    }),
    bgImageStyle: {
    },
    paperBody: {
        borderRadius: 8,
        minHeight: '290px',
        maxWidth: '1184px',
        margin: '0 auto',
    },
    helperText: {
        display: 'flex',
        position: 'absolute',
        alignItems: 'center',
        fontSize: '14px',
        overflowY: 'hidden',
        maxHeight: '0',
        top: '90%',
        transitionProperty: 'all',
        transitionDuration: '.5s',
        transitionTimingFunction: 'cubic-bezier(0, 1, 0.5, 1)',

        '@media screen and (max-width: 601px)': {
            top: '100%',
            fontSize: '12px',
            margin: 0,
        },
        '& svg': {
            fontSize: '14px',
            borderRadius: '50%',
            color: theme.palette.giftFinderErrorColor,
            backgroundColor: theme.palette.white,
            marginRight: '5px',
        },
    },
    findItButton: {
        '& .MuiButtonBase-root': {
            display: 'block',
            '@media screen and (max-width: 376px)': {
                margin: '0 auto',
                width: '90%',
                marginBottom: '10px',
            },
        },
    },
    errorMsg: {
        '& $helperText': {
            color: theme.palette.giftFinderErrorColor,
            maxHeight: '25px',

        },
        '& $select': {
            color: theme.palette.giftFinderErrorColor,
            borderColor: theme.palette.giftFinderErrorColor,
            '& svg': {
                fill: theme.palette.giftFinderErrorColor,
            },
        },
        '& .MuiInputLabel-formControl': {
            color: theme.palette.giftFinderErrorColor,
        },

    },
    elementHidden: {
        display: 'none',
    },
    recipientSelect: {
        '& .MuiNativeSelect-select': {
            padding: '16px 12px 0px !important',
            '@media screen and (max-width: 1024px)': {
                paddingTop: '13px !important',
            },
        },
    },
}));

function GraphqlFoodGiftFinder_v2(props) {
    const {
        data, history, track,
    } = props;
    const {
        isUniversalLander, ulGiftFinderCss, ffIsGFSuggestionsEnabled, flags,
    } = props;

    const isAggregatorFacetErrorResolver = useSelector(getFeatureFlag('is-facet-throwing-error-resolver-enabled'));

    let delimiter = '&';
    if (isAggregatorFacetErrorResolver) delimiter = '&facet=';

    const dataSelector = data?.reference?.[0];
    const productData = dataSelector?.product_recommendations || [];
    const giftFinderSuggestionEnable = ffIsGFSuggestionsEnabled && flags?.whichGiftFinderSuggestion === 'variant' && dataSelector?.has_recommendations;
    const backgroundImages = dataSelector.background_image;
    const device = (matches) => Object.keys(matches).find((key) => matches[key]); // returns device type string
    const imageSizes = {
        desktop: backgroundImages?.desktop?.url,
        tablet: backgroundImages?.tablet?.url, // can add fallbacks easily this way
        mobile: backgroundImages?.mobile?.url,
    };

    // queryString delimeter for images from branches
    const imgObjString = JSON.stringify(backgroundImages); // used elsewhere

    const imageQuality = '?auto=webp';
    const renderImagePrefetch = (matches) => <img src={`${imageSizes[device(matches)]}${imageQuality}`} width="0" height="0" alt="prefetch" />;
    const classes = useStyles({ dataSelector, imageSizes, imageQuality });
    // const classes = useStyles({ data });

    const [state, setState] = React.useState({
        occasion: '',
        recipient: '',
        price: '',
        occasionKey: '',
        recipientkey: '',
        priceKey: '',
        disabled: 'disabled',
    });

    const [errorMessage, setErrorMessage] = React.useState({
        occasionError: false,
        recipientError: false,
        priceError: false,
    });

    const [values, setValues]  = React.useState([]);    const handleChange = (event, keyName) => {
        const {
            options, selectedIndex, value, name,
        } = event.target;
        const inputLabel = options[selectedIndex].innerHTML.toLowerCase();
        setState({
            ...state,
            [name]: value,
            [keyName]: inputLabel?.replace(/\s/g, '').split('$').join(''), // this regex replacing all the white space and dollar sign inside a string
        });
        setErrorMessage({
            ...errorMessage,
            [`${name}Error`]: !value,
        });
        try {
            const selectedValues = values;
            const selectedSplitValue = value.split(delimiter);
            const index = selectedValues.findIndex((check) => check === selectedSplitValue);
            if (index > -1) {
                selectedValues.splice(0, index);
            } else {
                selectedValues.push(...selectedSplitValue);
            }
            setValues(selectedValues);
        } catch (error) {
            return error;
        }
        return null;
    };

    const { tracking_link_action = '', tracking_link_category = '' } = data || {};

    const trackData = () => {
        const eventCategory = data?.a_b_testing?.submission_tacking_event_category || data?.a_b_testing?.tracking_event_category ||  tracking_link_category || '';
        const eventAction = data?.a_b_testing?.submission_tacking_event_action || data?.a_b_testing?.tracking_event_action || tracking_link_action || '';
        const eventLabel = `${state.recipientkey}|${state.occasionKey}|${state.priceKey}`;
        track({
            eventCategory,
            eventAction,
            eventLabel,
        });
    };

    const validation = () => {
        let occasionError = false; let recipientError = false; let priceError = false;
        let error = true;
        if (!state.occasion) {
            occasionError = true;
            error = false;
        }
        if (!state.recipient) {
            recipientError = true;
            error = false;
        }
        if (!state.price) {
            priceError = true;
            error = false;
        }
        setTimeout(() => { // hide the error message after two second
            setErrorMessage({
                occasionError: false,
                recipientError: false,
                priceError: false,
            });
        }, 2000);

        setErrorMessage({
            occasionError,
            recipientError,
            priceError,
        });
        return error;
    };

    const handleOnClick = () => {
        if (validation()) {
            trackData();
            const querystring = `?facet=${state.recipient}${delimiter}${state.occasion}${delimiter}${state.price}`;
            const path = data.reference[0].collection_path ? `${data.reference[0].collection_path}` : '/';
            history.push({
                pathname: path,
                search: querystring,
                state: values,
            });
        }
    };

    const recipients = dataSelector.finder_criteria?.recipient?.value;
    const occasions = dataSelector.finder_criteria?.occasion?.value;
    const prices = dataSelector.finder_criteria?.price?.value;
    const headingImage = '<svg xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" version="1.1" width="32px" height="32px"><g transform="matrix(1 0 0 1 -544 -353 )"><path d="M 2 20  L 2 29.5454545454545  C 2 30.8025568181818  2.89375 31.8181818181818  4 31.8181818181818  L 14 31.8181818181818  L 14 20  L 2 20  Z M 32 11.3636363636364  C 32 10.1065340909091  31.10625 9.09090909090909  30 9.09090909090909  L 27.36875 9.09090909090909  C 27.75625 8.23153409090909  28 7.27982954545455  28 6.25  C 28 2.80539772727273  25.53125 0  22.5 0  C 19.9 0  18.21875 1.51278409090909  16.0625 4.85085227272727  C 13.90625 1.51278409090909  12.225 0  9.625 0  C 6.59375 0  4.125 2.80539772727273  4.125 6.25  C 4.125 7.27982954545455  4.3625 8.23153409090909  4.75625 9.09090909090909  L 2 9.09090909090909  C 0.89375 9.09090909090909  0 10.1065340909091  0 11.3636363636364  L 0 17.0454545454545  C 0 17.6704545454545  0.45 18.1818181818182  1 18  L 31 18  C 31.55 18.1818181818182  32 17.6704545454545  32 17.0454545454545  L 32 11.3636363636364  Z M 15 9.09090909090909  L 9.61875 9.09090909090909  C 8.2375 9.09090909090909  7.11875 7.81960227272727  7.11875 6.25  C 7.11875 4.68039772727273  8.2375 3.40909090909091  9.61875 3.40909090909091  C 10.8625 3.40909090909091  11.78125 3.64346590909091  15 9.09090909090909  Z M 25 6.25  C 25 7.81960227272727  23.88125 9.09090909090909  22.5 9.09090909090909  L 17.11875 9.09090909090909  C 20.33125 3.65767045454545  21.225 3.40909090909091  22.5 3.40909090909091  C 23.88125 3.40909090909091  25 4.68039772727273  25 6.25  Z M 18 20  L 18 31.8181818181818  L 28 31.8181818181818  C 29.10625 31.8181818181818  30 30.8025568181818  30 29.5454545454545  L 30 20  L 18 20  Z " fill-rule="nonzero" fill="#a30014" stroke="none" transform="matrix(1 0 0 1 544 353 )"/></g></svg>';

    const errorShowing = (errorMsg) => (
        <FormHelperText
            aria-hidden="true"
            className={`${classes.helperText} `}
        >
            <CancelOutlinedIcon />{errorMsg}
        </FormHelperText>
    );
    const { occasionError, priceError, recipientError } = errorMessage;
    const ffHasHomepageGridStructure = useSelector(getFeatureFlag('has-homepage-grid-structure'));
    const giftFinderGFBCssStyle = `${classes.giftFinderGFB} ${ffHasHomepageGridStructure && classes.giftFinderGFBWithGrid}`;
    return ( // Render Vertical Menus
        <div className={isUniversalLander ? `${giftFinderGFBCssStyle} ${ulGiftFinderCss} ${classes.giftFinderGFBBG} giftFinder` : `${giftFinderGFBCssStyle} ${classes.giftFinderGFBBG} giftFinder`}>
            <div className={classes.container}>
                <Paper elevation={3} className={classes.paperBody}>
                    <div className={classes.heading}>
                        {headingImage ? <div className={classes.headingImg}>{ReactHtmlParser(headingImage)}</div> : '' }
                        {dataSelector?.heading &&  <h2 aria-hidden="true" className={classes.headingCopy}>{dataSelector.heading}</h2>}
                    </div>
                    <Grid container justify="center">
                        <Grid item md={4} xs={12} className={`${classes.fieldBlock} ${recipientError ? classes.errorMsg : ''}`}>
                            <FormControl role="region" aria-label="Selectrecipient" variant="outlined">
                                <InputLabel className={!state.recipient && classes.elementHidden} htmlFor="recipient_select">Who&rsquo;s This For?</InputLabel>
                                <NativeSelect
                                    defaultValue=""
                                    className={state.recipient ? `${classes.select} ${classes.recipientSelect}` : classes.select}
                                    value={state.recipient}
                                    disableUnderline
                                    error
                                    onChange={(e) => handleChange(e, 'recipientkey')}
                                    inputProps={{
                                        name: 'recipient',
                                        id: 'recipient_select',
                                        'aria-hidden': 'true',
                                        tabIndex: '-1',
                                    }}
                                >
                                    <option className={state.recipient && classes.elementHidden} disabled aria-hidden="true" value="">Who&rsquo;s This For?</option>
                                    {
                                        recipients?.map((k, index) => <option key={uid(k, index)} aria-hidden="true" value={k.value}>{k.key}</option>)
                                    }
                                </NativeSelect>
                                {errorShowing('Please choose a recipient')}
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12} className={`${classes.fieldBlock}  ${occasionError ? classes.errorMsg : ''}`}>
                            <FormControl role="region" aria-label="Selectocssian" variant="outlined">
                                <InputLabel className={!state.occasion && classes.elementHidden} htmlFor="occasion_select">What&rsquo;s the Occasion?</InputLabel>
                                <NativeSelect
                                    defaultValue=""
                                    className={state.occasion ? `${classes.select} ${classes.recipientSelect}` : classes.select}
                                    value={state.occasion}
                                    disableUnderline
                                    onChange={(e) => handleChange(e, 'occasionKey')}
                                    error
                                    inputProps={{
                                        name: 'occasion',
                                        id: 'occasion_select',
                                        'aria-hidden': 'true',
                                        tabIndex: '-1',
                                    }}
                                >
                                    <option className={state.occasion && classes.elementHidden} disabled aria-hidden="true" value="">What&rsquo;s the Occasion?</option>
                                    {
                                        occasions?.map((k, index) => <option key={uid(k, index)} aria-hidden="true" value={k.value}>{k.key}</option>)
                                    }
                                </NativeSelect>
                                {errorShowing('Please choose an occasion')}
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12} className={`${classes.fieldBlock} ${priceError ? classes.errorMsg : ''}`}>
                            <FormControl role="region" aria-label="Selectbudget" variant="outlined">
                                <InputLabel className={!state.price && classes.elementHidden} htmlFor="price_select">What&rsquo;s Your Budget?</InputLabel>
                                <NativeSelect
                                    defaultValue=""
                                    className={state.price ? `${classes.select} ${classes.recipientSelect}` : classes.select}
                                    value={state.price}
                                    disableUnderline
                                    error
                                    onChange={(e) => handleChange(e, 'priceKey')}
                                    inputProps={{
                                        name: 'price',
                                        id: 'price_select',
                                        'aria-hidden': 'true',
                                        tabIndex: '-1',
                                    }}
                                >
                                    <option className={state.price && classes.elementHidden} disabled aria-hidden="true" value="">What&rsquo;s Your Budget?</option>
                                    {
                                        prices?.map((k, index) => <option key={uid(k, index)} aria-hidden="true" value={k.value}>{k.key}</option>)
                                    }
                                </NativeSelect>
                                { errorShowing('Please choose a budget')}
                            </FormControl>
                        </Grid>
                        <Grid role="region" aria-label="FindButton" item xs={12} className={classes.findItButton}>
                            <Button onClick={() => handleOnClick()} className={classes.button} variant="contained" color="primary">FIND IT</Button>
                        </Grid>
                    </Grid>
                </Paper>
                {imgObjString.includes('http') && <ResponsiveWrapper renderer={renderImagePrefetch} />}
            </div>
            {
                giftFinderSuggestionEnable && (
                    <div
                        className={classes.giftFinderHorizontal}
                        style={{
                            backgroundColor: productData?.styling?.module_background_color?.color,
                        }}
                    >
                        <GiftFinderProductContainer productData={productData} />
                    </div>
                )
            }
        </div>
    );
}

GraphqlFoodGiftFinder_v2.propTypes = {
    data: shape({
        facet_occasion: shape({
            occasion: shape({
                value: array.isRequired,
            }),
        }),
        facet_price: shape({
            price: shape({
                value: array.isRequired,
            }),
        }),
        facet_recipient: shape({
            recipient: shape({
                value: array.isRequired,
            }),
        }),
        heading: string.isRequired,
        heading_image: array.isRequired,
        landing_page: shape({
            title: string.isRequired,
            href: string.isRequired,
        }),
    }).isRequired,
    history: object.isRequired,
    track: func.isRequired,
    isUniversalLander: bool,
    ulGiftFinderCss: object,
    ffIsGFSuggestionsEnabled: bool.isRequired,
    flags: object.isRequired,
};

GraphqlFoodGiftFinder_v2.defaultProps = {
    isUniversalLander: false,
    ulGiftFinderCss: {},
};

const mapDispatchToProps = (dispatch) => ({
    track: bindActionCreators(trackEvent, dispatch),
});

const mapStateToProps = (state) => ({
    giftFinderV2FlagEnabled: getFeatureFlag('is-gift-finder-v2-enabled')(state),
    ffIsGFSuggestionsEnabled: getFeatureFlag('is-gift-finder-suggestions-enabled')(state),
    abTest: getActiveABTests(state),
});

export default compose(
    withLDConsumer(),
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
)(GraphqlFoodGiftFinder_v2);
